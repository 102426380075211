<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Solicitudes Nacionales</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Carga Seca</li>
                  <li class="breadcrumb-item active">Nacionales</li>
                  <li class="breadcrumb-item active">Solicitudes</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card" style="display: inherit">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-10"></div>
                  <div class="col-md-12">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal_export_solNa"
                        @click="limpiarExport()"
                        v-if="
                          $store.getters.can(
                            'funcionariofrontera.csSolicitudNacional.export'
                          )
                        "
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        v-if="
                          $store.getters.can(
                            'funcionariofrontera.csSolicitudNacional.create'
                          )
                        "
                        @click="create()"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                      <button
                        type="button"
                        class="btn bg-navy"
                        @click="getIndex()"
                      >
                        <i class="fas fa-sync-alt"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-danger"
                        @click="limpiarFiltro()"
                      >
                        <i class="fas fa-broom"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body pb-0">
                <div class="row justify-content-center">
                  <div class="form-group col-md-1">
                    <label># Solicitud</label>
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      v-model="filtros.id"
                      @input="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label>Nombre Usuario</label>
                    <input
                      type="text"
                      class="form-control form-control-sm p-0"
                      v-model="filtros.user"
                      @input="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-3">
                    <label>Tipo Solicitud</label>
                    <select
                      v-model="filtros.tipo_solicitud"
                      class="form-control form-control-sm"
                      placeholder="Tipo Solicitud"
                      label="descripcion"
                      @change="getIndex()"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estado in listasForms.tipos_solicitudes"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-md-2">
                    <label>Fecha</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="filtros.fecha"
                      @input="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-2">
                    <label>Estado</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="filtros.estado"
                      @change="getIndex()"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estado in listasForms.estados"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="card-body table-responsive p-0">
                <table
                  class="table table-bordered table-striped table-hover table-sm"
                  style="font-size: 0.9em"
                >
                  <thead class="thead bg-dark">
                    <tr class="text-center">
                      <th class="col-1">#</th>
                      <th>Nombre Usuario</th>
                      <th class="col-1">Tipo Solicitud</th>
                      <th class="col-1">Fecha</th>
                      <th>Justificación</th>
                      <th>Observaciones</th>
                      <th class="col-1">Estado</th>
                      <th class="col-1">Acciones</th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="solicitud in solicitudes.data"
                    :key="solicitud.id"
                  >
                    <tr>
                      <td class="text-center">
                        <b>
                          <button
                            type="button"
                            class="btn btn-xs btn-default"
                            style="cursor: pointer"
                            data-toggle="modal"
                            data-target="#modal-form-detalle-CsNacSolicitud"
                            v-if="$store.getters.can('tep.solicitudes.detalle')"
                            @click="llenarModal(solicitud)"
                          >
                            {{ solicitud.id }}
                          </button></b
                        >
                      </td>
                      <td class="text-center">
                        {{ solicitud.user.name }}
                      </td>
                      <td class="text-center">
                        <span
                          class="badge"
                          :class="
                            solicitud.tipo_solicitud == 1
                              ? 'bg-navy'
                              : 'bg-danger'
                          "
                          >{{ solicitud.tipoSolicitud }}</span
                        >
                        <div
                          class="row justify-content-center"
                          v-if="solicitud.tipo_emergencia == 2"
                        >
                          <span class="badge bg-pink">Extemporanea</span>
                        </div>
                        <div
                          class="row justify-content-center"
                          v-if="solicitud.tipo_emergencia == 1"
                        >
                          <span class="badge bg-info">Normal</span>
                        </div>
                      </td>
                      <td class="text-center">
                        {{ solicitud.fecha }}
                      </td>
                      <td>
                        <small>{{ solicitud.justificacion }}</small>
                      </td>
                      <td>
                        <small>{{ solicitud.observaciones }}</small>
                      </td>
                      <td class="text-center">
                        <span
                          class="badge"
                          :class="
                            solicitud.estado == 1
                              ? 'badge-info'
                              : solicitud.estado == 2
                              ? 'badge-success'
                              : solicitud.estado == 3
                              ? 'badge-danger'
                              : solicitud.estado == 4
                              ? 'badge-primary'
                              : solicitud.estado == 5
                              ? 'bg-warning'
                              : ''
                          "
                        >
                          {{ solicitud.estado }}. {{ solicitud.nEstado }}
                        </span>
                      </td>
                      <td style="width: 50px">
                        <div
                          class="btn-group float-right"
                          v-if="
                            solicitud.estado != 2 &&
                              solicitud.estado != 3 &&
                              solicitud.estado != 4
                          "
                        >
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            v-if="
                              $store.getters.can(
                                'funcionariofrontera.csSolicitudNacional.edit'
                              )
                            "
                            @click="edit(solicitud, 1)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                        </div>
                        <div class="btn-group float-right" v-else>
                          <button
                            type="button"
                            class="btn btn-sm bg-info"
                            v-if="
                              $store.getters.can(
                                'funcionariofrontera.csSolicitudInterna.show'
                              )
                            "
                            @click="edit(solicitud, 2)"
                          >
                            <i class="fas fa-eye"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="solicitudes.total">
                  Mostrando del <b>{{ solicitudes.from }}</b> al
                  <b>{{ solicitudes.to }}</b> de un total:
                  <b>{{ solicitudes.total }}</b> Registros
                </div>
                <div class="float-left" v-else>
                  <span class="badge badge-danger">
                    No hay registros para mostrar
                  </span>
                </div>
                <pagination
                  :data="solicitudes"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  class="float-right m-0"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <CsSolicitudesNacDetalle ref="CsSolicitudesNacDetalle" />
    <CsSolicitudesNacionalesExport
      ref="CsSolicitudesNacionalesExport"
    ></CsSolicitudesNacionalesExport>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import pagination from "laravel-vue-pagination";
import CsSolicitudesNacDetalle from "./CsSolicitudesNacDetalle";
import CsSolicitudesNacionalesExport from "./CsSolicitudesNacionalesExport";

export default {
  name: "SolicitudesNacionales",
  components: {
    Loading,
    pagination,
    CsSolicitudesNacDetalle,
    CsSolicitudesNacionalesExport,
  },
  data() {
    return {
      cargando: false,
      filtros: {},
      solicitudes: {},
      listasForms: {
        tipos_solicitudes: [],
        estados: [],
      },
    };
  },
  methods: {
    async getIndex(page = 1) {
      this.cargando = true;
      await axios
        .get(
          "/api/funcionariosFrontera/solicitudesNacionales/index?page=" + page,
          {
            params: this.filtros,
          }
        )
        .then((response) => {
          this.solicitudes = response.data;
          this.cargando = false;
        });
    },

    async getTipoSolicitud() {
      await axios.get("/api/lista/127").then((response) => {
        this.listasForms.tipos_solicitudes = response.data;
      });
    },

    create() {
      return this.$router.push({
        name: "/CargaSeca/SolicitudesNacionalesForm",
        params: { accion: "Crear" },
      });
    },

    edit(solicitud, act) {
      return this.$router.push({
        name: "/CargaSeca/SolicitudesNacionalesForm",
        params: { accion: "Editar", data_edit: solicitud, act: act },
      });
    },

    llenarModal(solicitud) {
      if (this.$store.getters.can("tep.solicitudes.detalle")) {
        this.$refs.CsSolicitudesNacDetalle.llenar_modal(solicitud);
      }
    },

    getEstados() {
      axios.get("/api/lista/135").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    limpiarExport() {
      (this.$refs.CsSolicitudesNacionalesExport.filtros = {
        gerente_area_id: null,
        fecha_inicial: null,
        fecha_final: null,
        tipo_solicitud: null,
      }),
        this.getIndex();
    },

    limpiarFiltro() {
      this.filtros = {};
      this.getIndex();
    },
  },

  mounted() {
    this.getIndex();
    this.getTipoSolicitud();
    this.getEstados();
  },
};
</script>
